<template>
  <div class='summarCollection-container'>
    <CSDialog
        :dialogTitle="dialogTitle"
        :dialogVisible="addRoomVisibleHy"
        dialog-confirm-btn-text="保存"
        dialogWidth="766px"
        @onClose="addRoomVisibleHy = false"
        @onConfirm="editRoomHy">
      <div slot="dialog-content" class="room-form">
        <div class="field-item">
          <label class="field-item-label">公摊汇总名称</label>
          <div class="field-item-content">
            <input
                v-model="editRoomInfo.equallySharedName"
                maxlength="20"
                placeholder="限20个字"
                type="text"
            />
          </div>
        </div>

        <div class="field-item" style="margin-bottom: 0px">
          <label class="field-item-label">计费起止日期</label>
          <div class="field-item-content">
            <div>
              <CSSelect i-width="40px" style="width: 180px; margin-right: 0;height: 40px;">
                <el-date-picker
                    v-model="editRoomInfo.collectBillStartDate"
                    :editable="false"
                    format="yyyy-MM-dd"
                    placeholder="请选择开始时间"
                    prefix-icon="el-icon-time"
                    style="width: 170px;font-size: 20px"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @change="startTimerBtn(false)"
                >
                </el-date-picker>
              </CSSelect>
              <span class="inline-gray"> </span>
              <CSSelect i-width="40px" style="width: 180px;height: 40px">
                <el-date-picker
                    v-model="editRoomInfo.collectBillEndDate"
                    :editable="false"
                    format="yyyy-MM-dd"
                    placeholder="请选择结束时间"
                    prefix-icon="el-icon-time"
                    style="width: 190px;font-size: 20px"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @change="endTimerBtn(false)"
                >
                </el-date-picker>
              </CSSelect>
            </div>

          </div>
        </div>

        <div class="field-item" style="color: #999999;">
          <label class="field-item-label"></label>
          <div class="field-item-content">
            <svg aria-hidden="true" class="icon" style="font-size: 20px;">
              <use xlink:href="#icon-menua-zu92"></use>
            </svg>
            <span style="font-size: 20px;">
                            私区水费用量将根据该日期统计。
                        </span>

          </div>
        </div>

        <div class="field-item jsF">
          <label class="field-item-label">计费起始日总表读数</label>
          <div class="field-item-content">
            <CSSelect height="40px" iWidth="36px">
              <select
                  v-model="startBuing"
                  :disabled="!isDisableStart"
                  :style="!isDisableStart?`background:rgba(170, 170, 170,0.1);color: #999999`:`color: #999999`"
                  style="width: 388px"
                  @change="dateChange"
              >
                <option value="">{{ !isDisableStart ? '无抄表记录' : '请选择' }}</option>
                <option
                    v-for="building in collectBillStartDateList"
                    :key="building.id"
                    :value="building"
                >
                  {{ '抄表时间' + building.readingTime + '抄表读数' + building.bottom }}
                </option>
              </select>
            </CSSelect>
          </div>
        </div>

        <div class="field-item jsF">
          <label class="field-item-label">计费截止日总表读数</label>
          <div class="field-item-content">
            <CSSelect height="40px" iWidth="36px">
              <select
                  v-model="endBuing"
                  :disabled="!isDisableEnd"
                  :style="!isDisableEnd?`background:rgba(170, 170, 170,0.1);color: #999999`:`color: #999999`"
                  style="width: 388px"
                  @change="dateChange"
              >
                <option value="">{{ !isDisableEnd ? '无抄表记录' : '请选择' }}</option>
                <option
                    v-for="building in collectBillEndDateList"
                    :key="building.id"
                    :value="building"
                >
                  {{ '抄表时间' + building.readingTime + '抄表读数' + building.bottom }}
                </option>
              </select>
            </CSSelect>
          </div>
        </div>

        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '园区总用电量' : '园区总用水量' }} </label>
          <div class="field-item-content">
            {{ parkSumber ? parkSumber : '0' }}{{ isElectric ? '度' : '吨' }}
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '私区总用电量' : '私区总用水量' }} </label>
          <div class="field-item-content">
            {{ privateWaterAmount ? privateWaterAmount : 0 }}{{ isElectric ? '度' : '吨' }}
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '公摊总用电量' : '公摊总用水量' }}</label>

          <div class="field-item-content">
            {{ publicShare ? publicShare : 0 }}{{ isElectric ? '度' : '吨' }}
          </div>


        </div>
        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '公摊电价' : '公摊水价' }}</label>
          <div class="field-item-content">
            {{ waterPrice ? waterPrice : 0 }}{{ isElectric ? '元/度' : '元/吨' }}
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '公摊电费总额' : '公摊水费总额' }}</label>
          <div class="field-item-content">
            {{ waterPriceTotal ? waterPriceTotal : 0 }}元
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">{{ isElectric ? '电费公摊总面积' : '水费公摊总面积' }}</label>
          <div class="field-item-content">
            {{ waterSpace }}m²
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">单位面积公摊费</label>
          <div class="field-item-content">
            {{ unitArea }}元/m²
          </div>
        </div>
        <div class="field-item jsF">
          <label class="field-item-label">预计生成账单数</label>
          <div class="field-item-content">
            {{ billCount }}
          </div>
        </div>


      </div>


    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import {
  addEquallySharedCollectUrl,
  modifyEquallySharedCollectUrl,
  queryRegionEquallySharedDataUrl,
  queryRegionWaterAndElectricitySumUrl
} from "@/requestUrl";
import {BigNumber} from "@/utils/bignumber.js";

export default {
  name: "SummarCollection",
  props: {
    dialogTitle: {
      default: '水费公摊汇总收缴'
    },
    isElectric: {
      default: false
    }
  },
  components: {
    CSDialog,
    CSSelect
  },
  computed: {

    isDisableStart() {

      return this.collectBillStartDateList && this.collectBillStartDateList.length > 0
    },
    isDisableEnd() {
      return this.collectBillEndDateList && this.collectBillEndDateList.length > 0
    },
    //公摊zongyongsuil
    publicShare() {
      return this.numberUtil.sub(this.parkSumber, this.privateWaterAmount);
    },
    //公摊水费总额
    waterPriceTotal() {
      if (this.publicShare && this.waterPrice) {
        let x = new BigNumber(this.publicShare);
        return x.times(this.waterPrice).toFixed(2);
      }
      return 0
    },
    //单位面积费用
    unitArea() {
      if (this.waterPriceTotal && this.waterSpace) {
        return (this.waterPriceTotal / this.waterSpace).toFixed(8)
      }
      return 0
    }
  },
  data() {
    return {
      isUpdateK: false,
      timerOutS: false,
      billSum: 0,
      isAdd: false,
      collectBillStartDateTon: '',//计费起始日抄表读数不能为空
      collectBillEndDateTon: '',//计费截止日抄表读数不能为空
      billCount: 0,//预计账单数
      waterSpace: 0,//公摊面积
      waterPrice: 0,//公摊水价
      privateWaterAmount: "",//私区总用水用电量
      collectBillStartDateList: [],//计费起始日数据列表
      collectBillEndDateList: [],//计费截止日数据列表
      addRoomVisibleHy: false,
      startBuing: "",//起始日数据
      summaryTableList: [],//总表数组
      endBuing: "",//截止日
      parkSumber: '',//园区总用水量
      editRoomInfo: {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        equallySharedName: '',//公摊汇总名称 必填
        collectBillStartDate: '',//计费开始日期
        collectBillEndDate: '',//计费截止日期
        userName: this.$vc.getCurrentStaffInfo().name,//用户名称
        userId: this.$vc.getCurrentStaffInfo().userId//用户ID
      }
    }
  },
  created() {
    this.$vc.on('addRoomVisibleHy', (item) => {
      this.addRoomVisibleHy = true;
      if (!item) {
        this.parkSumber = 0;
        this.waterSpace = 0;
        this.billCount = 0;
        this.waterPrice = 0;
        this.isAdd = true
        this.collectBillStartDateList = [];
        this.collectBillEndDateList = [];
        this.startBuing = "";//起始日数据
        this.endBuing = "";
        this.billSum = 0;
        this.editRoomInfo = {
          regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
          equallySharedName: '',//公摊汇总名称 必填
          collectBillStartDate: '',//计费开始日期
          collectBillEndDate: '',//计费截止日期
          userName: this.$vc.getCurrentStaffInfo().name,//用户名称
          userId: this.$vc.getCurrentStaffInfo().userId//用户ID
        }
      } else {
        this.isAdd = false;
        console.log(this.isAdd);
        console.log('显示的项');
        console.log(item);


        this.editRoomInfo = {
          regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
          equallySharedName: item.equallySharedName,//公摊汇总名称 必填
          collectBillStartDate: item.collectBillStartDate,//计费开始日期
          collectBillEndDate: item.collectBillEndDate,//计费截止日期
          userName: this.$vc.getCurrentStaffInfo().name,//用户名称
          userId: this.$vc.getCurrentStaffInfo().userId,//用户ID
          id: item.id
        }
        this.endTimerBtn(true, item);
        this.startTimerBtn(true, item);
      }
    })
  },
  mounted() {

  },
  methods: {
    //截止日期发生变化
    async dateChange() {
      if (this.startBuing && this.endBuing) {
        this.$fly.post(queryRegionWaterAndElectricitySumUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
          "startDate": this.startBuing.readingTime,  // 开始日期
          "endDate": this.endBuing.readingTime, // 结束日期
          "type": this.isElectric ? 2 : 1 // 类型 1-水 2-电
        }).then(res => {
          console.log('判断水电');
          console.log(this.isElectric ? 2 : 1);
          console.log('园区总用水量接口');
          if (res.code != 0) {
            return
          }

          console.log(res.data);
          this.parkSumber = res.data
        })
      }
    },
    //选择日期
    startTimerBtn(isU, it) {
      console.log('选择了日期');
      setTimeout(async () => {
        if (this.editRoomInfo.collectBillStartDate && this.editRoomInfo.collectBillStartDate != 'Invalid Date' && this.editRoomInfo.collectBillEndDate && this.editRoomInfo.collectBillEndDate != 'Invalid Date') {
          let arr = await this.queryCollect();

          if (arr.data.startDatas && arr.data.startDatas.length > 0) {
            let timerArr = arr.data.startDatas;
            if (isU, it) {
              this.startBuing = timerArr.filter(item => {
                return it.collectBillStartTonDate == item.readingTime
              }).map(re => re).sort((a, b) => {
                b.readingTime - a.readingTime
              })[0] || "";
            } else {
              this.startBuing = timerArr.filter(item => {
                let timer = dayjs(item.readingTime).format('YYYY-MM-DD');
                let dangTimer = dayjs(this.editRoomInfo.collectBillStartDate).format('YYYY-MM-DD');
                if (timer == dangTimer) {
                  return true
                }
              }).map(re => re).sort((a, b) => {
                b.readingTime - a.readingTime
              })[0] || "";
            }

            this.dateChange();
            this.privateWaterAmount = arr.data.privateWaterAmount;
            this.waterSpace = arr.data.waterSpace;
            this.billCount = arr.data.billCount;
            this.waterPrice = arr.data.waterPrice;

            if (this.isElectric) {
              this.privateWaterAmount = arr.data.privateElectricityAmount;
              this.waterSpace = arr.data.electricitySpace;
              this.waterPrice = arr.data.electricityPrice
            }
          }
          this.collectBillEndDateList = arr.data.endDatas;
          this.collectBillStartDateList = arr.data.startDatas;
          // if (this.editRoomInfo.collectBillStartDate && this.editRoomInfo.collectBillEndDate) {
          //     this.$fly.post(queryRegionEquallySharedDataUrl, {
          //         regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
          //         "startDate": this.editRoomInfo.collectBillStartDate + ':00',  // 开始日期
          //         "endDate": this.editRoomInfo.collectBillEndDate + ':00', // 结束日期
          //         "type": this.isElectric ? 2 : 1 // 类型 1-水 2-电
          //     }).then(res => {
          //        this.privateWaterAmount = res.data.privateWaterAmount;
          //        this.waterSpace = res.data.waterSpace;
          //        this.billCount = res.data.billCount;
          //     })
          // }
        }

      })

    },
    endTimerBtn(isU, it) {
      setTimeout(async () => {
        if (this.editRoomInfo.collectBillStartDate && this.editRoomInfo.collectBillStartDate != 'Invalid Date' && this.editRoomInfo.collectBillEndDate && this.editRoomInfo.collectBillEndDate != 'Invalid Date') {
          let arr = await this.queryCollect();
          if (arr.data.endDatas && arr.data.endDatas.length > 0) {
            let timerArr = arr.data.endDatas;
            if (isU) {
              console.log('timerArr');
              console.log(timerArr);
              console.log(it);
              this.endBuing = timerArr.filter(item => {
                return it.collectBillEndTonDate == item.readingTime
              }).map(re => re).sort((a, b) => {
                b.readingTime - a.readingTime
              })[0] || "";
            } else {
              this.endBuing = timerArr.filter(item => {
                let timer = dayjs(item.readingTime).format('YYYY-MM-DD');
                let dangTimer = dayjs(this.editRoomInfo.collectBillEndDate).format('YYYY-MM-DD');
                if (timer == dangTimer) {
                  return true
                }
              }).map(re => re).sort((a, b) => {
                b.readingTime - a.readingTime
              })[0] || "";
            }

          }
          this.collectBillStartDateList = arr.data.startDatas;
          this.collectBillEndDateList = arr.data.endDatas;
          this.dateChange();
          this.privateWaterAmount = arr.data.privateWaterAmount;
          this.waterSpace = arr.data.waterSpace;
          this.billCount = arr.data.billCount;
          this.waterPrice = arr.data.waterPrice;
          if (this.isElectric) {
            this.privateWaterAmount = arr.data.privateElectricityAmount;
            this.waterSpace = arr.data.electricitySpace;
            this.waterPrice = arr.data.electricityPrice
          }

        }


        /* let endEtimer = dayjs(this.editRoomInfo.collectBillEndDate).add(5, 'day').format('YYYY-MM-DD');
         let endStimer = dayjs(this.editRoomInfo.collectBillEndDate).subtract(5, 'day').format('YYYY-MM-DD');
         if (endStimer != 'Invalid Date') {
             let arr = await this.queryCollect(endStimer, endEtimer);
             if (arr.data.datas && arr.data.datas.length > 0) {
                 let timerArr = arr.data.datas;
                 this.endBuing = timerArr.filter(item => {
                     let timer = dayjs(item.createTime).format('YYYY-MM-DD');
                     let dangTimer = dayjs(this.editRoomInfo.collectBillEndDate).format('YYYY-MM-DD');
                     if (timer == dangTimer) {
                         return true
                     }
                 }).map(re => re).sort((a, b) => {
                     b.createTime - a.createTime
                 })[0]
             }


             this.dateChange();
             this.collectBillEndDateList = arr.data.datas;
             if (this.editRoomInfo.collectBillStartDate && this.editRoomInfo.collectBillEndDate) {
                 this.$fly.post(queryRegionEquallySharedDataUrl, {
                     regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
                     "startDate": this.editRoomInfo.collectBillStartDate + ':00',  // 开始日期
                     "endDate": this.editRoomInfo.collectBillEndDate + ':00', // 结束日期
                     "type": this.isElectric ? 2 : 1 // 类型 1-水 2-电
                 }).then(res => {
                     this.privateWaterAmount = res.data.privateWaterAmount;
                     this.waterSpace = res.data.waterSpace;
                     this.billCount = res.data.billCount;
                 })
             }
         }*/
      })
    },
    editRoomHy() {
      if (this.publicShare == 0) {
        this.$vc.toast("公摊总用量不能为0");
        return
      }
      if (!this.editRoomInfo.equallySharedName) {
        this.$vc.toast("公摊汇总名称不能为空");
        return
      }
      this.$CSDialog.confirm({
        title: '提示',
        message: `确认批量生成公摊账单吗？生成后需要手动下发账单。`,
        onConfirm: () => {
          if (this.timerOutS) {
            return
          }
          this.timerOutS = true;
          let url = modifyEquallySharedCollectUrl;
          if (this.isAdd) {
            url = addEquallySharedCollectUrl;
          }

          let params = {
            "equallySharedDosage": this.publicShare,  //公摊总用量
            "equallySharedPrice": this.waterPrice,  // 公摊费用价格
            "equallySharedTotalAmount": this.waterPriceTotal,  // 公摊费用总额
            "equallySharedSpace": this.waterSpace,  // 公摊总面积
            "equallySharedPriceAvg": this.unitArea,  // equallySharedPriceAvg
            "billSum": this.billCount, //预计生成账单数
            "type": this.isElectric ? 2 : 1, // 类型 1-水 2-电
            collectBillStartTonDate: dayjs(this.startBuing.readingTime).format('YYYY-MM-DD HH:mm:ss'),//计费起始日总表读数时间不能为空
            collectBillEndTonDate: dayjs(this.endBuing.readingTime).format('YYYY-MM-DD HH:mm:ss'),//计费截止日总表读数时间不能为空
            collectBillStartDateTon: this.startBuing.bottom,//计费起始日抄表读数不能为空
            collectBillEndDateTon: this.endBuing.bottom,//计费截止日抄表读数不能为空
            regionSum: this.parkSumber,//园区总用水用电量
            privateSum: this.privateWaterAmount,//私区总用水用电量不能为空
            ...this.editRoomInfo
          }
          if (this.isAdd) {
            this.$fly.post(url, {
              ...params
            }).then(res => {
              this.timerOutS = false;
              if (res.code != 0) {
                return
              }
              this.$vc.toast("添加成功");
              this.$CSDialog.instance.closeDialog();
              this.addRoomVisibleHy = false;
              this.$vc.emit('querySelect', {})

            })
          } else {
            this.$fly.put(url, {
              ...params
            }).then(res => {
              this.timerOutS = false;
              if (res.code != 0) {
                return
              }
              this.$vc.toast("修改成功");
              this.$CSDialog.instance.closeDialog();
              this.addRoomVisibleHy = false;
              this.$vc.emit('querySelect', {})
            })
          }

        }
      })


    },
    changeBuilding() {

    },
    //园区公区仪表
    queryCollect() {
      let params = {
        regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
        "startDate": this.editRoomInfo.collectBillStartDate,  // 开始日期
        "endDate": this.editRoomInfo.collectBillEndDate, // 结束日期
        "type": this.isElectric ? 2 : 1 // 类型 1-水 2-电
      }
      return this.$fly.post(queryRegionEquallySharedDataUrl, params)
    },

  }
}
</script>

<style lang="stylus" scoped>
.room-form {
  padding: 27px 30px 10px;

  .field-item {
    margin-bottom: 22px;
    font-size: 0;

    &-label {
      font-size: 24px;
      text-align: right;
      width: 208px;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-bottom: 0;
    }

    &-content {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;

      input {
        width: 400px;
        height: 40px;
        text-indent: 5px;
        border-radius: 4px;
        border: 1px solid #979797;

        &::placeholder {
          color: #999;
        }
      }

      select {
        width: 220px;
        text-indent: 5px;
      }
    }
  }

  .tips {
    color: #999;
    font-size: 20px;
  }

  .field-font {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 33px;
    color: #999999;
  }
}

.location-search {
  width: 100%;
  display: flex;
}

.location-search .location-input {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 10px;
  vertical-align: middle;
  width: 30px;
  height: 40px;
  box-sizing: border-box;
}

.font {
  font-size: 20px;
  vertical-align: middle;
  margin-left: 30px;
  float: right;
  height: 40px;
  padding: 0;
  width: 60px;
  line-height: 40px;
  box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
}

.selectDive {
  padding: 0;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
  position: relative;
  background-image: "../../assets/未选中.png"
}

input[type='checkbox'] {
  width: 0px !important
  display inline-block
  opacity 1
  margin-right 25px
  height 20px !important
}

.roomli {
  display flex
  align-items center
}

.roomMove {
  cursor pointer
}

.field-item-label {
  width 220px !important
}
</style>
<style>
.summarCollection-container .el-input__inner {
  padding-right: 10px !important;
}

.summarCollection-container .el-icon-caret-bottom {
  padding-top: 4px !important;
}

.jsF .el-icon-caret-bottom {
  padding-top: 0px !important;
}
</style>
